import React from "react"
import { graphql } from "gatsby"
import { css } from "@emotion/core"

import "src/queries/featured_media"

import PageLayout from "src/components/PageLayout"
import SEO from "../components/seo"
import DraftLabel from "../components/DraftLabel"
import * as util from "src/util"
import { useSiteMetadata } from "../hooks/use-site-metadata"

const wrapper = css`
  padding-top: 4rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: center;
  iframe {
    margin-bottom: 0;
    width: 100%;

    @media (max-width: 576px) {
      margin-right: -1.5rem;
      margin-left: -1.5rem;
      height: 270px;
      width: calc(100% + 3rem);
    }
  }
  @media (max-width: 1199px) {
    flex-direction: column;
    max-width: calc(670px + 3rem);
    margin-left: auto;
    margin-right: auto;
  }
`

const wrapperInner = css`
  width: 100%;
  max-width: 670px;
  margin-left: 26px;
  margin-right: 26px;
  @media (max-width: 1199px) {
    order: -1;
    margin-left: auto;
    margin-right: auto;
  }
`

const title = css`
  font-family: var(--heading);
  font-size: 40px;
  line-height: 1.3;
  color: black;
  font-weight: bold;
  margin-bottom: 12px;
  @media (max-width: 576px) {
    font-size: 28px;
  }
`

const intro = css`
  color: #4b4b4b;
  font-size: 21px;
  line-height: 1.52;
  margin-bottom: 40px;
  @media (max-width: 576px) {
    font-size: 18px;
  }
`

const ArticleTemplate = ({ data }) => {
  const post = data.wordpressWpFromTheLab
  const { siteUrl } = useSiteMetadata()
  let otherMetaTags = util.compileArticleSEOFields(post, siteUrl)
  const videoCode = post.acf.video.split("?v=")?.[1]
  const videoLink = `https://www.youtube-nocookie.com/embed/${videoCode}`
  return (
    <PageLayout options={{ headingBackground: false, light: true }}>
      <SEO
        yoastMeta={post.yoast_meta}
        title={post.title}
        ogType="article"
        extra={otherMetaTags}
        description={post.excerpt}
      />
      <div css={wrapper}>
        <div css={wrapperInner}>
          <DraftLabel status={post.status} />
          <h1 css={title}>{post.title}</h1>
          <div css={intro}>{post.acf.subtitle}</div>
          <iframe
            width="664px"
            height="391px"
            src={videoLink}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen="1"
            title={post.title}
          ></iframe>
        </div>
      </div>
    </PageLayout>
  )
}

export default ArticleTemplate

export const query = graphql`
  query FromTheLabArticleTemplate($id: String!) {
    wordpressWpFromTheLab(id: { eq: $id }) {
      title
      content
      excerpt
      slug
      date
      modified
      status
      acf {
        subtitle
        video
      }
      yoast_meta {
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_redirect
        yoast_wpseo_opengraph_title
        yoast_wpseo_opengraph_description
        yoast_wpseo_metakeywords
        yoast_wpseo_metadesc
        yoast_wpseo_meta_robots_noindex
        yoast_wpseo_meta_robots_nofollow
        yoast_wpseo_meta_robots_adv
        yoast_wpseo_linkdex
        yoast_wpseo_focuskw
        yoast_wpseo_canonical
      }
    }
  }
`
